import React from 'react';
import ReactPlayer from 'react-player';
import { useEffect, useState } from "react";
import {postStringToServer} from "./apiUtils";

const routeName = "cumpilation"

const VideoPlayer = () => {
    const [ipAddress, setIpAddress] = useState('');
    useEffect(() => {

        const handleSubmit = async () => {
            try {
                const response = await postStringToServer(routeName);
                // Handle the successful response if needed
                console.log('Server response:', response);
            } catch (error) {
                // Handle any errors that occur during the request
                console.error('Failed to post data:', error);
            }

        }
        handleSubmit()

    }, []);


    return (
        <div>
            <ReactPlayer
                /*url='/mnt/raid/Media/XXX/cuckoldsessions/CuckoldSessions.20.12.06.Cory.Chase.XXX.1080p.MP4-WRB[rarbg]/cuckoldsessions.20.12.06.cory.chase.mp4'*/
                /*url='https://www.youtube.com/watch?v=u2NuS1i-CcU'*/
                url='https://snimkov.topmaterial.org/cumpilation.webm'
                controls
                width='100%'
                height='100%'
            />
        </div>
    );
};
export default VideoPlayer;
