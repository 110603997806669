import React, {useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import LogoImg from './massivelogo.png'
import "./styles//HomePage.css"
import Cookies from 'js-cookie';
import GreenButton from "./GreenButton";
import axios from 'axios';
import { postStringToServer } from './apiUtils';

import { sendData } from './utils'; // Adjust the import path as necessary

const BACKEND_URL = process.env.REACT_APP_SENDIP_ENDPOINT;
const routeName = "home";
const HomePage = () => {
    const navigate = useNavigate();
    const goToPaymentPage = () => {
        navigate('/payment'); // Replace '/about' with your desired path

    };
    const goToVideoPreviewPage = () => {
        navigate('/cumpilation')
    }

    useEffect(() => {
        const handleSubmit = async () => {
            try {
                const response = await postStringToServer(routeName);
                // Handle the successful response if needed
                console.log('Server response:', response);
            } catch (error) {
                // Handle any errors that occur during the request
                console.error('Failed to post data:', error);
            }

        }
        handleSubmit()
        const dajba = Cookies.get('dajba');
        if (dajba === 'true') {
            navigate('/downloads')
        } else {
            navigate('/')
        }

    }, [navigate]);

    return (
        <div className="App">
            <header className="App-header">
                <img src={LogoImg} className="App-logo" alt="logoalt"/>
                <h1 id="welcome">Welcome to the world's largest <span id="porn">PORN</span> Collection!</h1>
                <h1>Yes, you read that right.</h1>
                <h1>This is a private porn collection of a person just like you - a lover of HQ erotica.</h1>
                <h1>Since there is no single place on the internet that is offering you <span
                    id="terabytes">TERABYTES</span> of content that you can copy with 100MB/s Download Speeds, I've decided to
                    provide one for you...</h1>
                <h1>It's got spicy content nowhere else to be seen... All HD in perfect condition:</h1>
                <ul id="features">
                    <li>Professional Studio Porn MegaPack ~7.2TB</li>
                    <li>Premium Onlyfans Content MegaPack worth over 1000$, including Photos, Videos, PPVs ~2.1T, Stories, Highlights</li>
                    <li>Instagram Hot Young Chicks MegaPack, personal selection, private and public profiles including photos, videos, highlights, stories, tagged... ~2.5TB </li>
                    <li>Telegram Channels that have the export turned off (undownloadable) with amateur erotica nowhere else to be seen (Pictures AND Videos) ~900G</li>
                    <li>Archives of leaked private porn on MEGA clouds. ~855GBG</li>
                    <li>Pornpics Selection (Here it is screenshots of best moments only), PhotoDromm and Playboy ~7GB </li>
                    <li>Photos of MILFs and college students on dating platform chat.bg (naughty 2005 nokia pics, sexy!)</li>
                    <li>Sexy girls with big tits on TikTok !!!</li>
                </ul>
                <a className="App-link" href="" target="_blank"
                   rel="noopener noreferrer">
                </a>
                <div onClick={goToVideoPreviewPage}>
                    <GreenButton text="Want a video compilation preview? Click Here." />
                </div>
                <a>

                </a>
                <div onClick={goToPaymentPage}>
                    <GreenButton text="10 TB of Premium Porn only for 99.99$? What a deal..." onClick={goToPaymentPage}/>
                </div>
                <h2>Something wrong? Write to our support mail: <a href="mailto:chovekutshema@proton.me">Send Mail</a></h2>
                <h3>Or Telegram Us <a href="https://t.me/chovekat">Send Message</a></h3>
            </header>
        </div>

    );
}
export default HomePage;
