import Completion from "./Completion";
import HomePage from "./HomePage";
import VideoPreviewPage from "./VideoPreviewPage";
import {BrowserRouter, Routes, Route, useNavigate} from "react-router-dom";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";

import Payment from "./Payment";

import Downloads from "./Downloads"

import { loadStripe } from '@stripe/stripe-js';

import OpenReplay from "@openreplay/tracker"

const DEBUG_MODE = process.env.DEBUG_MODE === 'true';
console.log(DEBUG_MODE)
if (DEBUG_MODE) {
    const tracker = new OpenReplay({
        projectKey: "dn5YOecWXBWmvbtcQSUo",
        ingestPoint: "https://openreplay.topmaterial.org/ingest",
        respectDoNotTrack: true});
    tracker.start(); //  returns a promise with session info (sessionID, sessionHash, userUUID)
        }
else{
    console.log("OpenReplay tracking is disabled in debug mode.");
}

function App() {
    //useEffect(() => { // use componentDidMount in case of React Class Component
    //    tracker.start(); //  returns a promise with session info (sessionID, sessionHash, userUUID)
    //}, [])

    const [ stripePromise, setStripePromise ] = useState(null);

    useEffect(() => {
        fetch(process.env.REACT_APP_CONFIG_ENDPOINT).then(async (r) => {
            const { publishableKey } = await r.json();
            setStripePromise(loadStripe(publishableKey));
        });
    }, []);

    /*
    useEffect(() => {
        console.log('env vars are : ')
        Object.keys(process.env)
            .filter(key => key.startsWith('REACT_APP_'))
            .forEach(key => {
                console.log(`${key}: ${process.env[key]}`);
            });
    }, []);
    */

  return (
    <main>
        <BrowserRouter>
          <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/cumpilation" element={<VideoPreviewPage />} />
              <Route path="/downloads" element={<Downloads />} />
              <Route path="/payment" element={<Payment stripePromise={stripePromise} />} />
              <Route path="/completion" element={<Completion stripePromise={stripePromise} />} />
          </Routes>
        </BrowserRouter>
      </main>
  );
}
export default App;
