import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import {useEffect, useState} from "react";
import "./styles/Completion.css"

const routeName = "completion"
function Completion(props) {

    const navigate = useNavigate();
    const handlePaymentSuccess = () => {

        // Set a cookie that marks the user as having paid
        Cookies.set('dajba', 'true', { expires: 365 }); // Expires in 7 days

        navigate('/downloads')
    };

    return (
        <div id="thankyou">
            <h1>Payment has been received. Thank you very much for your support! 🎉</h1>
            <h1>And now download all of it... Please click the button below to redirect to downloads page.</h1>
            <button className="animated-button" onClick={handlePaymentSuccess}>Click Me</button>

        </div>
    )
};

export default Completion;
