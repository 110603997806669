import axios from 'axios';
export async function postStringToServer(myString) {
    try {
        const url =  process.env.REACT_APP_CREATE_ACCESS_ENDPOINT; // Update with your actual endpoint
        const response = await axios.post(url, {
            data: myString // Sending the string in an object with the property 'data'
        });
        console.log(response.data); // Log the response from the server
        return response.data; // Optionally return the response data for further processing
    } catch (error) {
        console.error('Error posting string to server:', error.response ? error.response.data : error);
        throw error; // Optionally re-throw to handle it in the calling component
    }
}
