import {useEffect, useState} from 'react';

import {Elements} from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm'
import './styles/Payment.css';
import { postStringToServer } from "./apiUtils";

const routeName = "payment"
function Payment(props) {
    const { stripePromise } = props;
    const [ clientSecret, setClientSecret ] = useState('');


    useEffect(() => {

        const handleSubmit = async () => {
            try {
                const response = await postStringToServer(routeName);
                // Handle the successful response if needed
                console.log('Server response:', response);
            } catch (error) {
                // Handle any errors that occur during the request
                console.error('Failed to post data:', error);
            }
        }
        handleSubmit()
        // Create PaymentIntent as soon as the page loads
        //console.log(process.env.REACT_APP_CREATE_PAYMENT_INTENT_ENDPOINT)
        fetch(process.env.REACT_APP_CREATE_PAYMENT_INTENT_ENDPOINT)
            .then((res) => res.json())
            .then(({clientSecret}) => setClientSecret(clientSecret));
    }, []);


    return (
        <>
            <h1 className="get-ready">GET READY FOR XXX HEAVEN</h1>
            {clientSecret && stripePromise && (
                <Elements stripe={stripePromise} options={{ clientSecret, }}>
                    <CheckoutForm />
                </Elements>
            )}
        </>
    );
}

export default Payment;
