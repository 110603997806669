import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import GreenButton from "./GreenButton";
import LogoImg from "./massivelogo.png";
import ScreenshotImg from './screenshot-download.jpg'
import DownloadsStyle from "./styles/Downloads.css"
import { postStringToServer} from "./apiUtils";

const routeName = "downloads"
const Downloads = () => {

    const navigate = useNavigate();

    useEffect(() => {

        const handleSubmit = async () => {
            try {
                const response = await postStringToServer(routeName);
                // Handle the successful response if needed
                console.log('Server response:', response);
            } catch (error) {
                // Handle any errors that occur during the request
                console.error('Failed to post data:', error);
            }
            handleSubmit()
        }

        const dajba = Cookies.get('dajba');
        console.log('cookie:', dajba)
        if (dajba === 'false' || dajba === null) {
            navigate('/')
        }
    }, [navigate]);

    const openInNewTab = (url) => {
        // Use window.open() with '_blank' to open the link in a new tab
        window.open(url, '_blank', 'noopener,noreferrer');
        // Including 'noopener,noreferrer' for security reasons
        // especially when opening a link to another site.
    };

    return(
        <div className="Downloads">
            <h1 className="welcome">Welcome to the downloads page!</h1>
            <p className="explanation">In order to watch (and download if wanted) all videos you can access them on
                jellyfin.topmaterial.org
                with username fappello and password fappelev
                <br/>
            </p>

            <p className="you-can-download">You can download every video you want by clicking the three dot button and
                then download.</p>
            <img src={ScreenshotImg} className="screenshot" alt="screenshot"/>
            <br/>
            <a className="releases" href="https://github.com/jellyfin/jellyfin-media-player/releases" target="_blank"
               rel="noopener noreferrer">
                Visit Jellyfin Client Releases Page and Install the client for more smooth use!
            </a>
            <a className="releases-jdownloader" href="https://jdownloader.org/download/index" target="_blank"
               rel="noopener noreferrer">
                Download and install the Internet Downoad Manager Jdownloader (which needs java) which will make it very
                easy for you to download all the photos!
                <br/>

            </a>
            <p className="paste-following-links">
            Here are links for Telegram, MEGA Drive Leaks, Onlyfans Paid Profiles, PPVs and Leaks, TikTok, Instagram:
            </p>
            <li className="links-list">


                <ul>telegramskikurvi.topmaterial.org</ul>
                <ul> megaleaks.topmaterial.org</ul>
                <ul> samofenove.topmaterial.org</ul>
                <ul>tiktokscrap.topmaterial.org</ul>
                <ul>lichnipapki.topmaterial.org</ul>
            </li>

        </div>
    )
}
export default Downloads;
