import React from 'react';
import './styles/GreenButton.css'; // Import the CSS file for styling
/*import {Elements} from '@stripe/react-stripe-js';*/
/*import {loadStripe} from '@stripe/stripe-js';*/
const GreenButton = ({ text, onClick }) => {
    /*const handleClickPreview = () => {
        console.log('Button clicked!');
        window.location.href = "https://beinsa.topmaterial.org/";
    };*/


    return (
        <button className="green-button">
            {text}
        </button>
    );
};
export default  GreenButton;
